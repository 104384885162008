html {
  font-family: "Satoshi", sans-serif;
  font-size: 16px;

  @apply bg-darkGunmetal text-cultured;

  @media screen(lg) {
    font-size: 20px;
  }
}

body {
  @media screen(lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

h1 {
  font-weight: 700;
  font-size: 2.875rem;
  line-height: 100%;

  @media screen(lg) {
    font-size: 4.6rem;
  }
}

h2 {
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 100%;

  @media screen(lg) {
    font-size: 2.1rem;
  }
}

h3 {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.75rem;

  @media screen(lg) {
    font-size: 2.1rem;
    line-height: 2.2rem;
  }
}

h4 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;

  @apply my-2 text-cheese;

  @media screen(lg) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

h5 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;

  @apply my-2 text-cheese;

  @media screen(lg) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

h6 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;

  @apply my-2 text-cheese;
}

p {
  font-weight: 300;
  line-height: 1.25rem;
}

button {
  font-weight: 300;
  font-size: 1.5rem;
  transition:
    background-color 0.1s,
    transform 0.1s;

  @apply rounded-full bg-redOrange px-12 py-1.5;

  @media screen(lg) {
    font-size: 1.2rem;
  }
}

button:hover {
  background-color: theme(colors.redOrange / 80%);
  transform: translateY(-1px);

  @apply drop-shadow-md;
}

section {
  @apply p-4;

  @media screen(lg) {
    padding: 0;
    margin: 80px;
    margin-top: 0px;
    max-width: 1280px;
  }
}

section:target {
  margin-top: 100px;
}

a {
  @apply text-sm font-light text-cheese underline;
}

nav {
  z-index: 999;
}

nav a {
  @media screen(lg) {
    text-decoration: none;
    color: theme(colors.cultured);
    font-weight: 500;
  }
}

textarea {
  resize: none;
}

.text-button {
  font-family: "Figree", sans-serif;
  font-weight: 300;
  font-size: 0.63rem;
  letter-spacing: 0.1rem;

  @apply uppercase;
}

.preload * {
  transition: none !important;
}
