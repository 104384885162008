#how-we-work .card {
  @apply rounded-lg bg-onyx px-4 py-2;

  @media screen(lg) {
    border-radius: 24px;
    padding: 32px;
  }
}

#how-we-work .underline {
  @apply h-[1px] w-full bg-cheese;
}
