#services .cards {
  scrollbar-width: none; /* Firefox */

  @apply flex snap-x flex-row overflow-x-scroll scroll-smooth;

  @media screen(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  @media screen(lg) {
    display: flex;
  }
}

#services .cards::-webkit-scrollbar {
  display: none;
}

#services .card {
  flex: 0 0 75%;

  @apply mr-5 rounded-lg bg-arsenic p-6;

  @media screen(md) {
    margin: 0;
  }

  @media screen(lg) {
    flex: 1;
  }
}

#services .card h6 {
  @apply min-h-20;
}
