#why-us .logos {
  @apply mt-8 grid grid-cols-2 items-center justify-center gap-x-2 gap-y-5;

  @media screen(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

#why-us .logos .logo {
  @apply flex items-center justify-center text-center;
}

#why-us .logos .logo img {
  max-height: 28px;
  max-width: 75%;
  object-fit: contain;

  @media screen(lg) {
    max-height: 56px;
    max-width: 50%;
  }
}
