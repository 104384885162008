:root {
  --longest-orbit-time: 180s;
}

.overline {
  @apply h-[3px] w-[90px] bg-white;

  @media screen(lg) {
    height: 5px;
  }
}

.accent {
  mix-blend-mode: saturation;

  @apply absolute -z-10 w-full overflow-hidden;
}

.accent > div {
  background: radial-gradient(
    theme("colors.lightGunmetal"),
    theme("colors.lightGunmetal"),
    theme("colors.darkGunmetal"),
    theme("colors.darkGunmetal")
  );

  @apply h-[650px] w-[650px];

  @media screen(lg) {
    width: 1300px;
    height: 1300px;
  }
}

.circle {
  @apply absolute h-[48px] w-[48px] rounded-full bg-lavenderGray;

  @media screen(lg) {
    width: 110px;
    height: 110px;
  }
}

.values-illustration {
  transform: scale(1.2) rotate(-35deg);
}

.planet {
  animation:
    move linear infinite,
    hide linear infinite;
  transform-style: preserve-3d;
  z-index: 1;

  @apply absolute inset-0 rounded-full;
}

#mercury {
  animation-duration: var(--longest-orbit-time);
  offset-path: ellipse(42.5% 13.25% at 55.5% 42.5%);

  @apply h-[16px] w-[16px] bg-redOrange;
}

#venus {
  animation-duration: calc(0.8 * var(--longest-orbit-time));
  animation-delay: calc(-0.25 * var(--longest-orbit-time));

  offset-path: ellipse(36.25% 11.25% at 55.5% 42.5%);

  @apply h-[14px] w-[14px] bg-heatWave;
}

#earth {
  animation-duration: calc(0.6 * var(--longest-orbit-time));
  animation-delay: calc(-0.25 * var(--longest-orbit-time));

  offset-path: ellipse(30% 9% at 55.5% 42.5%);

  @apply h-[10px] w-[10px] bg-sunnyDay;
}

#mars {
  animation-duration: calc(0.4 * var(--longest-orbit-time));
  animation-delay: calc(-0.85 * var(--longest-orbit-time));

  offset-path: ellipse(24.25% 7% at 55.5% 42.5%);

  @apply h-[8px] w-[8px] bg-strawCoop;
}

@keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes hide {
  0% {
    z-index: 15;
  }
  50% {
    z-index: 15;
  }
  51% {
    z-index: 1;
  }
  100% {
    z-index: 1;
  }
}
