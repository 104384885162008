#us .card {
  flex: 0 0 75%;

  @apply mb-5 rounded-lg bg-onyx pb-2 pt-4;

  @media screen(lg) {
    flex: 1;
    height: auto;
    margin-bottom: auto;
    padding-top: 0;
  }
}
