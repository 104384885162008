#header {
  transition: top 0.2s ease-in-out;

  @apply fixed inset-0 flex h-[100px] w-svw flex-row items-center justify-between px-4;

  @media screen(lg) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

#header .background {
  background: linear-gradient(
    to top,
    theme(colors.starlingFeather / 0%),
    theme(colors.starlingFeather / 50%) 25%,
    theme(colors.starlingFeather / 80%) 75%
  );
  backdrop-filter: blur(4px);
  mask-image: linear-gradient(
    to top,
    transparent,
    theme(colors.starlingFeather / 25%) 25%,
    theme(colors.starlingFeather) 80%
  );

  @apply absolute inset-0 -z-50;
}

.menu {
  @apply flex cursor-pointer flex-row items-center gap-2;

  @media screen(lg) {
    display: none;
  }
}

.menu p {
  font-family: "Figree", sans-serif;
  font-weight: 300;
  font-size: 0.63rem;
  letter-spacing: 0.1rem;

  @apply uppercase;
}
